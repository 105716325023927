import * as React from "react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import Gallery from "../components/Gallery";

const PortraiturePage = () => (
  <Layout descriptionText={"Portraiture"}>
    <Seo title="portraiture" />

    <Gallery categoryName="portraiture" />
  </Layout>
);

export default PortraiturePage;
